import React, { Component } from "react";
import "../../../css/partner-with-us.css";
import { Col, Container, Row } from "react-bootstrap";

class ReasonCard extends Component {
  render() {
    const { img, label, desc, overideStyle } = this.props;
    return (
      <div className={`partner_reason_card ${overideStyle?.container}`}>
        <img src={img} className="partner_reason_card_img" />
        <div className="partner_reason_card_content">
          <h1 className="partner_reason_card_label">{label}</h1>
          <p className="partner_reason_card_desc">{desc}</p>
        </div>
      </div>
    );
  }
}

class PartnerReason extends Component {
  render() {
    const { data, assets } = this.props;
    if (!data) return null;
    return (
      <Container fluid className="c2 partner_reason_container">
        <div className="container-lg partner_reason_root_content">
          <h1 className="partner_reason_header section_heading">{data.heading}</h1>
          <div className=" d-flex flex-row justify-content-center">
            <div className="partner_reason_content">
              {[...data.banner]?.map((item, i) => {
                return (
                  <div
                    key={i}
                    className=" d-flex flex-row justify-content-center"
                  >
                    <ReasonCard
                      img={assets?.[item.img]?.url}
                      label={item.label}
                      desc={item.desc}
                      overideStyle={item?.overideStyle}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default PartnerReason;
